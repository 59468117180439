import React from 'react'
import { Container } from '@mui/material'
import BaseView from '../../../template/baseView.tsx'
import TitleView from '../../../component/title/titleView.tsx'
import NewsList from './newsList.tsx'
import './news.scss'

const MainView = () => {
  return (
    <div className='news'>
      <Container maxWidth='md'>
        <TitleView english='NEWS' japaniese='お知らせ' />
        <NewsList max={10} isPagination={true} />
      </Container>
    </div>
  )
}

const News2024View = () => {
  return <BaseView main={<MainView />} />
}

export default News2024View
