import React from 'react'
import './artistBoxView.scss'
import SlideUpAnimation from '../component/animation/slideUpAnimation.tsx'

export type ArtistProps = {
  artistData: ArtistType
}

export type ArtistType = {
  artistName: string
  artistImg: string
  day1?: boolean
  day2?: boolean
  day3?: boolean
  read?: string
}

const ArtistBoxView = (props: ArtistProps) => {
  const { artistData } = props

  return (
    <SlideUpAnimation>
      <div className='artist-box'>
        <div className='img-area'>
          <img src={artistData.artistImg} alt='' />
        </div>
        <div className='artist-name'>{artistData.artistName}</div>
        <div className='tag-area'>
          {artistData.day1 ? <span className='day1'>19日</span> : ''}
          {artistData.day2 ? <span className='day2'>20日</span> : ''}
          {artistData.day3 ? <span className='day3'>21日</span> : ''}
        </div>
      </div>
    </SlideUpAnimation>
  )
}

export default ArtistBoxView
