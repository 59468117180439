import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from '@mui/material'
import { BaseView2025 } from '../../2025/layout/index.tsx'

const MainView = () => {
  return (
    <div className=''>
      <Container maxWidth='lg'>
        ページが存在しません。
        <Link to='/'>トップ</Link>に戻る
      </Container>
    </div>
  )
}

const NotFoundView = () => {
  return <BaseView2025 main={<MainView />} />
}

export default NotFoundView
