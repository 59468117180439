import React from 'react'
import './artistModal.scss'
import { ArtistProps } from '../../template/artistBoxView'

const ArtistModal = (props: ArtistProps) => {
  const { artistData } = props
  return (
    <div className='artist-modal'>
      <div className='img-area'>
        <img src={artistData.artistImg} alt='' />
      </div>
      <div className='artist-name'>{artistData.artistName}</div>
      <div className='concept'>{/* {artistData.concept} */}</div>
    </div>
  )
}

export default ArtistModal
