import React, { createContext, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import Home2024View from './view/2024/home/home.tsx'
import About2024View from './view/2024/about/about.tsx'
import News2024View from './view/2024/news/news.tsx'
import NewsDetail2024View from './view/2024/news/newsDetail.tsx'
import Ticket2024View from './view/2024/ticket/ticket.tsx'
import Lineup2024View from './view/2024/lineup/lineup.tsx'
import Timetable2024View from './view/2024/timetable/timetable.tsx'
import GreetingTable2024View from './view/2024/timetable/greetimgTable.tsx'
import Map2024View from './view/2024/map/map.tsx'
import Access2024View from './view/2024/access/access.tsx'
import NotFoundView from './view/common/notfound/notFound.tsx'
import Yosen2024View from './view/2024/yosen/yosen.tsx'
import Attention2024View from './view/2024/attention/attention.tsx'
import { Home2025View } from './view/2025/home/index.tsx'
import { News2025View } from './view/2025/news/news.tsx'
import { NewsDetail2025View } from './view/2025/news/newsDetail.tsx'
import { CommingSoonView } from './view/common/commingSoon/commingSoon.tsx'
import { Entry2025View } from './view/2025/entry/index.tsx'
import { Entry2025DetailView } from './view/2025/entry/detail/index.tsx'

export const AppContext = createContext({})

export const App = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation()

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [pathname])

    return null
  }

  return (
    <AnimatePresence>
      <ScrollToTop />
      <Routes>
        <Route path='/2024' element={<Home2024View />} />
        <Route path='/2024/about' element={<About2024View />} />
        <Route path='/2024/news' element={<News2024View />} />
        <Route path='/2024/news/:newsId' element={<NewsDetail2024View />} />
        <Route path='/2024/ticket' element={<Ticket2024View />} />
        <Route path='/2024/lineup' element={<Lineup2024View />} />
        <Route path='/2024/live' element={<Timetable2024View />} />
        <Route path='/2024/greeting' element={<GreetingTable2024View />} />
        <Route path='/2024/map' element={<Map2024View />} />
        <Route path='/2024/notice' element={<Attention2024View />} />
        <Route path='/2024/access' element={<Access2024View />} />
        <Route path='/2024/yosen' element={<Yosen2024View />} />
        <Route path='/' element={<Home2025View />} />
        <Route path='/about' element={<CommingSoonView  />} />
        <Route path='/news' element={<News2025View />} />
        <Route path='/news/:newsId' element={<NewsDetail2025View />} />
        <Route path='/ticket' element={<CommingSoonView />} />
        <Route path='/lineup' element={<CommingSoonView />} />
        <Route path='/live' element={<CommingSoonView />} />
        <Route path='/greeting' element={<CommingSoonView />} />
        <Route path='/map' element={<CommingSoonView />} />
        <Route path='/notice' element={<CommingSoonView />} />
        <Route path='/access' element={<CommingSoonView />} />
        <Route path='/yosen' element={<CommingSoonView />} />
        <Route path='/entry' element={<Entry2025View />} />
        <Route path='/entry/2025' element={<Entry2025DetailView />} />
        <Route path='*' element={<NotFoundView />} />
      </Routes>
    </AnimatePresence>
  )
}
