import React from 'react';
import './titleView.scss';

interface Title {
  english: string,
  japaniese: string
}

const TitleView = (props: Title) => {
  const {english, japaniese} = props;

  return (
    <h2 className='title-view'>
      <div className='title-decoration left'></div>
      <div className='title-font'>
        {english}
        <div className='ja'>{japaniese}</div>
      </div>
      <div className='title-decoration right'></div>
    </h2>
  );
}

export default TitleView;
