import React, { useEffect, useRef } from 'react'
import { BaseView2025 } from '../layout/index.tsx'
import { Container } from '@mui/material'
import ContentTitleView from '../../../component/title/contentTitleView.tsx'
import { NewsList } from '../news/newsList.tsx'
import { Link, useNavigate } from 'react-router-dom'
import SponsorshipView from '../../2024/home/sponsorship.tsx'
import { Frame } from '../../../component/frame/frame.tsx'
import TwitterTimeline from '../../../component/timeline/twitterTimeline.tsx'
import cooporationData from '../../../resource/2025/cooperation.json'
import { EllipseButton } from '../../../component/button/ellipseButton.tsx'

export const Home2025View = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    videoRef.current?.play()
  }, [])

  const navigate = useNavigate()

  return (
    <BaseView2025>
      <div className='flex flex-col gap-5 pb-10'>
        <div>
          <video autoPlay={true} loop={true}>
            <source
              src='https://sekigahara-idolwars.net/images/2025/Sekigahara2025_AORI.mp4'
              type='video/mp4'
            />
          </video>
        </div>
        <Container maxWidth='md'>
          <div className='flex flex-col gap-8'>
            <div>
              <ContentTitleView>お知らせ</ContentTitleView>
              <NewsList max={5} />
              <div className='flex text-center justify-center py-6'>
                <Link to='/news' className='bg-white border border-sekigahara rounded-md p-2'>
                  お知らせ一覧
                </Link>
              </div>
            </div>
            {/* <div>
              <ContentTitleView>予戦会エントリー</ContentTitleView>
              <Frame>
                <div className='text-center'>
                  <EllipseButton
                    className='bg-sekigahara text-white hover:bg-[#fe7e7e] px-4'
                    onClick={() => navigate('/entry')}
                  >
                    エントリーフォームへ
                  </EllipseButton>
                </div>
              </Frame>
            </div> */}
            {/* <div>
            <ContentTitleView>協賛</ContentTitleView>
            <Frame>
              <SponsorshipView data={[]} />
            </Frame>
          </div> */}
            <div>
              <ContentTitleView>協力</ContentTitleView>
              <Frame>
                <SponsorshipView data={cooporationData} />
              </Frame>
            </div>
            <div className=''>
              <ContentTitleView>公式X</ContentTitleView>
              <Container className='h-[800px] overflow-scroll' maxWidth='sm'>
                <TwitterTimeline />
              </Container>
            </div>
          </div>
        </Container>
      </div>
    </BaseView2025>
  )
}
