import React from 'react'
import { AccordionView } from '../../../component/accordion/accordion.tsx'
import './yosen.scss'

export const OwariView = () => {
  return (
    <div className='owari-view'>
      <AccordionView
        className='owari'
        title='其の十二'
        date='2024年5月26日（日）'
        place='SOUND SPACE DEEP'
        open='17:30'
        start='18:00'
        artists='究極人形/OS☆K/AMOUR/ミラクルファンファーレ！/PrincessGarden-NAGOYA-'
        image1='https://sekigahara-idolwars.net/images/2024/owari/12/1.jpeg'
        ticket='https://tiget.net/events/322798'
      />
      <AccordionView
        className='owari'
        title='其の十一'
        date='2024年5月26日（日）'
        place='SOUND SPACE DEEP'
        open='13:00'
        start='13:30'
        artists='究極人形/AQA/AMOUR/ミラクルファンファーレ！/PrincessGarden-NAGOYA-'
        image1='https://sekigahara-idolwars.net/images/2024/owari/11/1.jpeg'
        ticket='https://tiget.net/events/322796'
      />
      <AccordionView
        className='owari'
        title='其の十'
        date='2024年5月25日（土）'
        place='SOUND SPACE DEEP'
        open='18:00'
        start='18:30'
        artists='究極人形/AQA/OS☆K/PrincessGarden-NAGOYA-'
        image1='https://sekigahara-idolwars.net/images/2024/owari/10/1.jpeg'
        ticket='https://tiget.net/events/322560'
      />
      <AccordionView
        className='owari'
        title='其の九'
        date='2024年5月25日（土）'
        place='SOUND SPACE DEEP'
        open='14:00'
        start='14:30'
        artists='究極人形/AQA'
        image1='https://sekigahara-idolwars.net/images/2024/owari/9/1.jpeg'
        ticket='https://tiget.net/events/322558'
      />
      <AccordionView
        className='owari'
        title='其の八'
        date='2024年5月19日（日）'
        place='SOUND SPACE DIVA'
        open='17:30'
        start='18:00'
        artists='究極人形/AMOUR/PrincessGarden-NAGOYA-/AQA/ミラクルファンファーレ！/マイノリティアラート/OS☆K'
        image1='https://sekigahara-idolwars.net/images/2024/owari/8/1.jpeg'
        image2='https://sekigahara-idolwars.net/images/2024/owari/8/2.jpeg'
        ticket='https://tiget.net/events/321143'
      />
      <AccordionView
        className='owari'
        title='其の七'
        date='2024年5月19日（日）'
        place='SOUND SPACE DIVA'
        open='13:00'
        start='13:30'
        artists='究極人形/AMOUR/PrincessGarden-NAGOYA-/Cent Heaven'
        image1='https://sekigahara-idolwars.net/images/2024/owari/7/1.jpeg'
        image2='https://sekigahara-idolwars.net/images/2024/owari/7/2.jpeg'
        ticket='https://tiget.net/events/321142'
      />
      <AccordionView
        className='owari'
        title='其の六'
        date='2024年5月18日（土）'
        place='Lion Limited'
        open='17:45'
        start='18:15'
        artists='AQA/OS☆K/AMOUR/Le☆miel/PrincessGarden-NAGOYA-/ミラクルファンファーレ！'
        image1='https://sekigahara-idolwars.net/images/2024/owari/6/1.jpeg'
        image2='https://sekigahara-idolwars.net/images/2024/owari/6/2.jpeg'
        ticket='https://tiget.net/events/320944'
      />
      <AccordionView
        className='owari'
        title='其の五'
        date='2024年5月18日（土）'
        place='Lion Limited'
        open='13:00'
        start='13:30'
        artists='Le☆miel/PrincessGarden-NAGOYA-/Cent Heaven/究極人形'
        image1='https://sekigahara-idolwars.net/images/2024/owari/5/1.jpeg'
        image2='https://sekigahara-idolwars.net/images/2024/owari/5/2.jpeg'
        ticket='https://tiget.net/events/320942'
      />
      <AccordionView
        className='owari'
        title='其の四'
        date='2024年5月15日（水）'
        place='NAGOYA ReNY limited'
        open='17:45'
        start='18:15'
        artists='究極人形/AQA/AMOUR/Le☆miel/ミラクルファンファーレ！/PrincessGarden-NAGOYA-/Cent Heaven'
        image1='https://sekigahara-idolwars.net/images/2024/owari/4/1.jpeg'
        image2='https://sekigahara-idolwars.net/images/2024/owari/4/2.jpeg'
        ticket='https://tiget.net/events/320462'
      />
      <AccordionView
        className='owari'
        title='其の三'
        date='2024年5月12日（日）'
        place='名古屋DEEP'
        artists='究極人形/AMOUR/SITRA./Le☆miel'
        image1='https://sekigahara-idolwars.net/images/2024/owari/3/1.jpeg'
        image2='https://sekigahara-idolwars.net/images/2024/owari/3/2.jpeg'
        ticket='https://tiget.net/events/319999'
      />
      <AccordionView
        className='owari'
        title='其の二'
        date='2024年5月11日（土）'
        place='名古屋DEEP'
        open='17:00'
        start='17:30'
        artists='AQA/OS☆K/Le☆miel/ミラクルファンファーレ！'
        image1='https://sekigahara-idolwars.net/images/2024/owari/2/1.jpeg'
        image2='https://sekigahara-idolwars.net/images/2024/owari/2/2.jpeg'
        ticket='https://tiget.net/events/319807'
      />
      <AccordionView
        className='owari'
        title='其の一'
        date='2024年5月11日（土）'
        place='名古屋DEEP'
        open='12:00'
        start='12:30'
        artists='究極人形/AMOUR/AQA/<ゲスト>mistress'
        image1='https://sekigahara-idolwars.net/images/2024/owari/1/1.jpeg'
        image2='https://sekigahara-idolwars.net/images/2024/owari/1/2.jpeg'
        ticket='https://tiget.net/events/319806'
      />
    </div>
  )
}
