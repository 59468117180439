import React from 'react'
import { Container } from '@mui/material'
import BaseView from '../../../template/baseView.tsx'
import TitleView from '../../../component/title/titleView.tsx'
import './about.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import OutlineView from '../outline/outline.tsx'
import imgUrls from '../../../resource/aboutImages.json'
import YoutubeView from '../../../component/youtubeView/youtubeView.tsx'

interface Data {
  data: Array<string>
}

const Slider = (props: Data) => {
  const { data } = props

  return (
    <div className='swiper-container'>
      <Swiper
        modules={[Autoplay, Navigation, Pagination]}
        pagination={{ clickable: false, el: '#pagination' }}
        mousewheel={true}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        speed={500}
        centeredSlides={true}
        loop={true}
        breakpoints={{
          0: {
            slidesPerView: 2.2,
          },
          992: {
            slidesPerView: 2.8,
          },
          1800: {
            slidesPerView: 5.3,
          },
        }}
      >
        {Object.values(data).map((data, index) => {
          return (
            <SwiperSlide key={index}>
              <div className='img-area'>
                <img className='event-card-img' src={data} alt='' />
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

const MainView = () => {
  return (
    <div className='about'>
      <Container maxWidth='md'>
        <TitleView english='ABOUT' japaniese='イベント概要' />
        <div className='about-view'>
          <img
            src='https://sekigahara-idolwars.net/images/2024/logo/concept-back.png'
            alt='concept-view'
          />
          <div className='concept'>
            <div className='concept-font'>十回目の夏に</div>
            <div className='concept-font'>この景色を目の当たりにする</div>
            <div className='concept-font'>唄姫は、私たちだ。</div>
          </div>
        </div>
        <Slider data={imgUrls} />

        <YoutubeView
          videoId='csDUXCgKOcw?si=YcziHtwFrezxI9y-'
          title='SEKIGAHARA IDOL WARS2023 Special Thank You Movie'
        />
        <YoutubeView
          videoId='44a49WFCq3I?si=RvXMa4Gl8Vv2x5-z'
          title='SEKIGAHARA IDOL WARS2022 Special Thank You Movie'
        />
        <OutlineView />
      </Container>
    </div>
  )
}

const About2024View = () => {
  return <BaseView main={<MainView />} />
}

export default About2024View
