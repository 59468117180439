import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from 'react-modal'
import CooliePolicy from '../../../component/cookie/cookiePolicy.tsx'

const Footer = () => {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)

  return (
    <div className='flex flex-col gap-4 py-6 text-center bg-[#e14040]'>
      <Link to='/' className='flex justify-center pt-4'>
        <img
          className='w-[50%] min-w-[200px] max-w-[500px]'
          src='https://sekigahara-idolwars.net/images/2025/logo.png'
          alt='logo'
        />
      </Link>
      <div className='footer-content'>
        <button className='text-white' onClick={() => setEditModalIsOpen(true)}>
          プライバシーポリシー
        </button>
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={() => setEditModalIsOpen(false)}
          overlayClassName='overlay'
          className='modal-md'
        >
          <CooliePolicy />
        </Modal>
      </div>
      <div className='text-white'>IDOLWARS実行委員会 2025 All Right Reserved.</div>
    </div>
  )
}

export default Footer
