import React from 'react'
import { Container } from '@mui/material'
import BaseView from '../../../template/baseView.tsx'
import TitleView from '../../../component/title/titleView.tsx'
import './ticket.scss'
import { Link } from 'react-router-dom'

const MainView = () => {
  return (
    <div className='ticket'>
      <Container maxWidth='md'>
        <TitleView english='TICKET' japaniese='チケット情報' />
        <div className='ticket-img-view'>
          <img src='https://sekigahara-idolwars.net/images/2024/home/ticket.jpg' alt='ticket1' />
        </div>
        <Link to='https://tiget.net/events/330277'>
          <div className='ticket-link-btn'>チケットの購入はこちら</div>
        </Link>

        <div>▼必ず注意事項をご確認お願いします。</div>
        <Link to='/notice'>
          <div className='ticket-notice'>注意事項</div>
        </Link>
      </Container>
    </div>
  )
}

const Ticket2024View = () => {
  return <BaseView main={<MainView />} />
}

export default Ticket2024View
