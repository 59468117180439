import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './header.scss'
import Menu from '../../../view/2024/menu/menu.tsx'
import MenuSp from '../../../view/2024/menu/menu_sp.tsx'

const Header = () => {
  const [value, setValue] = useState(false)

  const classToggle = () => {
    setValue(!value)
  }

  return (
    <div className='header-body'>
      <Link to='/2024' className='logo'>
        <img
          className='logo'
          src='https://sekigahara-idolwars.net/images/2024/logo/headerlogo.svg'
          alt='logo'
        />
      </Link>
      <div className='menu-nav-pc'>
        <Menu displayMode={'inline-flex'} />
      </div>
      <div className='menu-nav-sp'>
        <MenuSp displayMode={value ? 'block' : 'none'} />
      </div>
      <div className={`hamburger-menu ${value ? 'active' : ''}`} id='menu01' onClick={classToggle}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default Header
