import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { Container, Grid } from '@mui/material'
import BaseView from '../../../template/baseView.tsx'
import TitleView from '../../../component/title/titleView.tsx'
import ArtistBoxView, { ArtistProps } from '../../../template/artistBoxView.tsx'
import './lineup.scss'
import data from '../../../resource/artists.json'
import ArtistModal from '../../../component/modal/artistModal.tsx'

const MainView = () => {
  const [units, setUnit] = useState(data)
  const [day, setDay] = useState('all')

  const onClickDay1 = () => {
    if ('day1' === day) {
      setDay('all')
      setUnit(data)
    } else {
      setDay('day1')
      setUnit(
        data.filter((unit) => {
          return unit.day1
        }),
      )
    }
  }

  const onClickDay2 = () => {
    if ('day2' === day) {
      setDay('all')
      setUnit(data)
    } else {
      setDay('day2')
      setUnit(
        data.filter((unit) => {
          return unit.day2
        }),
      )
    }
  }

  const onClickDay3 = () => {
    if ('day3' === day) {
      setDay('all')
      setUnit(data)
    } else {
      setDay('day3')
      setUnit(
        data.filter((unit) => {
          return unit.day3
        }),
      )
    }
  }

  useEffect(() => {
    setUnit(data)
  }, [])

  const ArtistBoxModal = (props: ArtistProps) => {
    const [editModalIsOpen, setEditModalIsOpen] = useState(false)
    const { artistData } = props

    return (
      <div className='modal-base'>
        <button className='modal-btn' onClick={() => setEditModalIsOpen(true)}>
          <ArtistBoxView artistData={artistData} />
        </button>
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={() => setEditModalIsOpen(false)}
          overlayClassName='overlay'
          className='modal-md'
        >
          <ArtistModal artistData={artistData} />
          <div className='modal-footer'>
            <button onClick={() => setEditModalIsOpen(false)} className='modal-close-btn'></button>
          </div>
        </Modal>
      </div>
    )
  }

  return (
    <div className='lineup'>
      <Container maxWidth='lg'>
        <TitleView english='LINE UP' japaniese='出演者情報' />
        <div className='describe'>（50音順）</div>
        <Grid
          container
          style={{ margin: '20px 0 5px 0', alignItems: 'center', justifyContent: 'center' }}
        >
          <Grid item sm={4}>
            <button className={`button day1`} onClick={() => onClickDay1()}>
              19日
            </button>
          </Grid>
          <Grid item sm={4}>
            <button className={`button day2`} onClick={() => onClickDay2()}>
              20日
            </button>
          </Grid>
          <Grid item sm={4}>
            <button className={`button day3`} onClick={() => onClickDay3()}>
              21日
            </button>
          </Grid>
        </Grid>
        <Grid container style={{ margin: '0', alignItems: 'center', justifyContent: 'center' }}>
          {Object.values(units).map((unit, index) => {
            return (
              <Grid item xs={6} md={4} lg={3} key={index}>
                <ArtistBoxModal artistData={unit} />
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </div>
  )
}

const Lineup2024View = () => {
  return <BaseView main={<MainView />} />
}

export default Lineup2024View
