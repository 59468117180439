import React, { useState } from 'react'
import { z } from 'zod'
import { Alert, Container } from '@mui/material'
import { BaseView2025 } from '../../layout/index.tsx'
import TitleView from '../../../../component/title/titleView.tsx'
import { Frame } from '../../../../component/frame/frame.tsx'
import { company, email, group_name, name } from '../../../../component/schema.ts'
import { init, send } from '@emailjs/browser'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TextFieldForm } from '../../../../component/form/TextFieldForm.tsx'
import { HiOutlineChevronDoubleDown } from 'react-icons/hi'

export const Entry2025DetailView = () => {
  const [emailStatusMessage, setEmailStatusMessage] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const EntrySchema = z.object({
    company: company,
    group_name: group_name,
    name: name,
    email: email,
  })

  type ContactType = z.infer<typeof EntrySchema>

  const sendEmail = async (data: ContactType) => {
    setEmailStatusMessage(false)
    setError(false)
    try {
      init('IdTWr2VgMdRiCW1AG')
      await send('service_lurdshc', 'sekigahara_entry', data)

      setEmailStatusMessage(true)
    } catch (error) {
      setError(true)
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ContactType>({
    mode: 'onChange',
    resolver: zodResolver(EntrySchema),
  })

  return (
    <BaseView2025>
      <Container maxWidth='md'>
        <TitleView english='予戦会概要' japaniese='エントリー' />
        <div className='flex flex-col gap-6 py-6'>
          <Frame>
            <Header title='予戦会概要' />
            <div className='flex flex-col gap-1 p-3'>
              <p>
                本イベントは「関ケ原唄姫合戦2025（本戦）」への出演をかけたイベントです。
                <br />
                期間は5/1〜5/25（ライブは5/9〜5/25）で行います。
                <br />
                参戦者の上位20組が本戦出演が決定します。
              </p>
              <br />
              <br />
              <div className='text-sekigahara font-bold text-xl'>開催地域</div>
              <p className='pl-2'>
                東京（江戸の乱）、名古屋（尾張・美濃の乱）、大阪（大阪の乱）の地域で開催されます。
              </p>
              <div className='text-sekigahara font-bold text-xl'>ルール</div>
              <p className='pl-2'>
                有観客ライブでの投票（投俵）ポイント＋METALIVE配信ポイントの合算によって順位を決定します。
              </p>
            </div>
          </Frame>
          <Frame>
            <Header title='予戦会参戦資格・条件' />
            <div className='flex flex-col gap-1 p-3'>
              <p>
                予戦会への参戦には下記の資格・条件
                <span className='text-red-600 font-bold'>全てに</span>満たす必要があります。
              </p>
              <p>
                いずれか一つでも満たない場合は資格を喪失します。上位20位に入っていた場合も出演ができなくなりますのでご注意ください。
              </p>
              <br />
              <div className='text-sekigahara font-bold text-xl'>参戦資格・条件</div>
              <ul>
                <li>
                  ・国内でアイドル活動をしているユニットおよびソロの方（事務所所属の有無は問わず）
                </li>
                <li>・予戦会有観客ライブに3回以上の出演ができること（出演は最大10回まで）</li>
                <li>
                  ・関ケ原唄姫合戦2025本戦の出演日程（7/19,20,21）の3日間スケジュールを空けておけること
                </li>
                <li>・METALIVEを期間中（5/1〜5/25）で25時間以上の配信ができること</li>
                <li>
                  ・Withコレを期間中（5/1〜5/25）で20万ポイント以上のデジタルコンテンツ販売ができること
                </li>
              </ul>
            </div>
          </Frame>
          {/* <Frame>
            <Header title='有観客ライブについて' />
            <div className='flex flex-col gap-1 p-3'></div>
          </Frame>
          <Frame>
            <Header title='METALIVE配信について' />
            <div className='flex flex-col gap-1'></div>
          </Frame>
          <Frame>
            <Header title='Withコレについて' />
            <div className='flex flex-col gap-1'></div>
          </Frame> */}
          <Frame>
            <Header title='エントリーフロー' />
            <div className='flex flex-col gap-4'>
              <p>エントリー受付開始は2025年4月1日からとなります。</p>
              <div className='flex flex-col gap-4 text-center items-center'>
                <TodoContent
                  src='https://www.shoshinsha-design.com/wp-content/uploads/2022/01/%E6%9B%B8%E9%A1%9E%E3%82%A2%E3%82%A4%E3%82%B3%E3%83%B3-760x460.png'
                  alt='form'
                  message='エントリーフォームより必要事項を入力する'
                />
                <HiOutlineChevronDoubleDown className='w-[100px] h-[50px]' />
                <TodoContent
                  src='https://user.meta-live.jp/static/media/metalive.968fd73208a6f0ae6043dd3a1b43b3c2.svg'
                  alt='METALIVE'
                  message='METALIVEのアカウントの作成'
                />
                <HiOutlineChevronDoubleDown className='w-[100px] h-[50px]' />
                <TodoContent
                  src='https://www.withlive.jp/img/withlive-logo.png'
                  alt='WithLIVEコレクション'
                  message='WithLIVEコレクションのアカウントの作成'
                />
              </div>
            </div>
          </Frame>
          {/* <Frame>
            <Header title='エントリーフォーム' />
            <p className='my-5 text-red-500'>エントリーはユニットごとに送信お願いします。</p>
            <form onSubmit={handleSubmit((e) => sendEmail(e))}>
              <div className='flex flex-col gap-4'>
                <TextFieldForm
                  title='ユニット名'
                  required
                  placeholder='ユニット名、活動名'
                  register={register('group_name')}
                  error={errors.group_name?.message}
                />
                <TextFieldForm
                  title='所属事務所名'
                  register={register('company')}
                  error={errors.company?.message}
                />
                <TextFieldForm
                  title='ご担当者名'
                  required
                  placeholder='山田　太郎'
                  register={register('name')}
                  error={errors.name?.message}
                />
                <TextFieldForm
                  title='メールアドレス'
                  required
                  placeholder='mail@example.com'
                  register={register('email')}
                  error={errors.email?.message}
                  type='email'
                />
                <button
                  type='submit'
                  disabled={!isValid || isSubmitting}
                  className='bg-slate-800 hover:bg-slate-600 rounded px-4 py-2 text-white  disabled:bg-gray-300 md:self-center'
                >
                  ENTRY
                </button>
                {emailStatusMessage && (
                  <Alert severity='success'>
                    関ケ原唄姫合戦2025 予戦会へのエントリーありがとうございます。
                  </Alert>
                )}
                {error && (
                  <Alert severity='error'>
                    エントリーの受付に失敗しました。時間をおいて再度送信お願いします。
                  </Alert>
                )}
              </div>
            </form>
          </Frame> */}
        </div>
      </Container>
    </BaseView2025>
  )
}

const Header = ({ title }: { title: string }) => {
  return (
    <h3 className='flex text-center text-2xl font-bold border-b-[#e14040] border-b-2 mb-6 py-2 text-[#e14040]'>
      {title}
    </h3>
  )
}

const TodoContent = ({ src, alt, message }: { src: string; alt: string; message: string }) => {
  return (
    <div className='flex flex-col w-full text-center border border-sekigahara rounded-md bg-slate-200 gap-4 py-3 px-2'>
      <img src={src} alt={alt} className='w-1/2 self-center' />
      <p className='font-bold'>{message}</p>
    </div>
  )
}
