import React from 'react'
import { Grid } from '@mui/material'
import Strings from '../../../const/strings.tsx'
import { Link } from 'react-router-dom'

type imgType = {
  link: string
  logo: string
}

interface Logo {
  data: Array<imgType>
}

const SponsorshipView = (props: Logo) => {
  const { data } = props

  if (!data || !data.length) {
    return (
      <Grid container spacing={2} style={{ margin: '0', width: '100%' }}>
        {Strings.noData}
      </Grid>
    )
  }
  return (
    <Grid container style={{ margin: '0', alignItems: 'center', justifyContent: 'center' }}>
      {Object.values(data).map((data, index) => {
        return (
          <Grid item xs={4} sm={4} key={index}>
            {data.link ? (
              <Link to={data.link} target='_blank'>
                <img style={{ width: '100%' }} alt='' src={data.logo} />
              </Link>
            ) : (
              <img style={{ width: '100%' }} alt='' src={data.logo} />
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default SponsorshipView
