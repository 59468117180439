import React from 'react'
import { Container } from '@mui/material'
import TitleView from '../../../component/title/titleView.tsx'
import { NewsList } from './newsList.tsx'
import './news.scss'
import { BaseView2025 } from '../layout/index.tsx'

export const News2025View = () => {
  return (
    <BaseView2025>
      <div className='news'>
        <Container maxWidth='md'>
          <TitleView english='NEWS' japaniese='お知らせ' />
          <NewsList max={10} isPagination={true} />
        </Container>
      </div>
    </BaseView2025>
  )
}
